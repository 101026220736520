<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
    >

      <v-card>
        <v-card-title>
          <span class="text-h5">Booking Detail</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <ul>
              <li class="red--text" v-for="(e, idx) in errors" :key="idx">{{ e }}</li>
            </ul>
            <v-form
                ref="booking"
                v-model="valid"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <v-select
                      :items="rooms"
                      item-text="name"
                      item-value="id"
                      label="Room*"
                      v-model="form.room_id"
                      :rules="[Rule.required]"
                  ></v-select>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-text-field
                      label="Description*"
                      v-model="form.description"
                      :rules="[Rule.required]"
                  ></v-text-field>
                </v-col>

                <v-col
                    cols="12" sm="6"
                >
                  <!--<v-datetime-picker
                    label="Start At"
                    v-model="form.start_at"
                    :rules="[Rule.required, Rule.future(form.start_at, 1)]"
                    :text-field-props="{
                       errorMessages: null,
                    }"
                  > </v-datetime-picker>-->

                  <datetime-picker
                    label="Start At"
                    v-model="form.start_at"
                    :rules="[Rule.required, Rule.future.bind(this, new Date())]"
                  ></datetime-picker>
                </v-col>

                <v-col cols="12" sm="6">
                  <datetime-picker
                      label="End At"
                      v-model="form.end_at"
                      :rules="[Rule.required, Rule.future.bind(this, form.start_at)]"
                  ></datetime-picker>
                  <!--<v-datetime-picker label="End At" v-model="form.end_at" :rules="[Rule.required]"> </v-datetime-picker>-->
                </v-col>

                <v-col cols="12">
                  <v-combobox
                      v-model="form.users"
                      :items="attenders"
                      item-text="name"
                      item-value="id"
                      label="Attenders"
                      multiple
                      chips
                      :rules="[Rule.required, Rule.min(form.users, 2), Rule.max(form.users, capacity)]"
                  ></v-combobox>
                </v-col>

              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click.stop="onSubmit"
              :disabled="!valid || submitting"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import Rule from '@/rules';
import { getRooms, getAttenders, booking } from "@/api/booking";
import DatetimePicker from "@/components/DatetimePicker";

export default {
  name: 'BookingCreate',
  components: {DatetimePicker},
  data: () => ({
    Rule,
    dialog: true,
    form: {},
    valid: true,
    submitting: false,
    rooms: [],
    attenders: [],
    errors: []
  }),
  created() {
    getRooms().then((response) => {
      this.rooms = response.data.data;
    });
    getAttenders().then((response) => {
      this.attenders = response.data.data;
    })
  },
  computed: {
    capacity() {
      let capacity = 0;
      this.rooms.forEach((e) => {
        if(e.id === this.form.room_id) {
          capacity = e.capacity;
        }
      });
      return capacity;

    }
  },

  methods: {
    close() {
      this.$router.push({name: 'Booking'});
    },

    onSubmit() {
      this.submitting = true;
      this.errors = null;

      let payload = {
        room_id: this.form.room_id,
        description: this.form.description,
        start_at: this.form.start_at,
        end_at: this.form.end_at,
        users: this.form.users.map(a => a.id)
      }
      booking(payload).then((response) => {
        switch (response.status) {
          case 201: {
            this.$emit('created:booking');
            this.$router.push({name: 'Booking'});
            break;
          }
          case 422: {
            this.errors = response.data;
            break;
          }
          default: {
            this.errors = ['Unknown error'];
            break;
          }
        }
      }).finally(() => {
        this.submitting = false;
      });

    }
  }
}
</script>